:root {
  --brand-color: #b82921;
}
html,body{font-size:18px; line-height:22px;}
section.header_banner{text-align:center;border-radius:0;margin:0 auto;padding:5px 0;overflow:hidden;min-height: 90px;}
section.header_banner img{vertical-align:top;}

figure{margin-left:0;margin-right:auto;}

.skyscraper {background-repeat: no-repeat; display: block; height: 1024px; position: absolute; top: 124px; z-index: 1000;}
.skyscraper.fixed{position: fixed; top: 0px;}
#skyscraper_left{right:50%; margin-right: 610px;}
#skyscraper_right{left:50%; margin-left: 610px;}
header.header { border-top: var(--brand-color) solid 5px; }
header.header div#publisher {text-align:center;}
span.title_hashtags{background:url(/images/layout/title_hashtags.png) no-repeat scroll left 1px;float:left;width:110px;height:20px;display:block;text-indent:-9999em;white-space:nowrap;overflow:hidden;}
.box_social_m{background:#eee;border-bottom:1px solid #ccc;padding-bottom:.5em;}
header.header .navbar .date-search{height:48px;}
header.header .navbar .date-search p{position:relative;z-index:100;}
header.header .navbar .date-search .date{font-size:12px;margin:1em 1em 0 0;}
header.header .navbar .date-search .search{margin:.5em .5em 0 0;}
header.header .navbar .date-search .search input { margin-right: -30px;}
header.header .navbar .date-search .search button {background:url(/images/icons/lupa.png) no-repeat scroll left 0;padding-right:25px \9;border: none; height: 20px; width: 26px;}

header.header h1>a>svg { width: 305px; height:54px; fill:var(--brand-color); margin:0 auto;}
header.header .anys {text-transform: uppercase;font-weight: bold;margin-bottom: 1em; }
header.header .anys>span { color: var(--brand-color);}

.media { position: relative; }
.media::before {
    pointer-events: none;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
    color: #fff;
    font-size: 175%;
    background: rgba(0,0,0,.45);
    padding: 0.7rem;
    border-radius: 5px;
}
.media.video::before { content: '\f04b'; }
.video-js button.vjs-big-play-button { background-color: var(--brand-color); }
.video-holder { background-color: #333; margin-bottom: 1rem; clear: both;}
.video-holder .vertical-video { margin: 0 auto; max-width: 300px; max-height: 750px; }
.video-holder .vertical-video-done { transition: max-height 0.2s cubic-bezier(0, 1.01, 0.96, 1); overflow: hidden; max-height: 210px; }

@media (min-width: 768px) { 
    .video-holder .vertical-video { max-width: 330px; } 
    .video-holder .vertical-video-done { max-height: 340px; } 
}

div.robapaginas { text-align: center; margin: 0 auto; justify-content: center; flex-basis: 600px;}
div.robapaginas.sticky { flex-basis: 600px; }
div.robapaginas.sticky>div { position: sticky; top: 0; }

.container .faldones_promocion {margin-bottom: .75em; }

/* RSS fondo en images/fondo_menu.jpg */
.nav {background: #333;}
.navbar .nav>li>a { display:inline-block; color: #fff; text-shadow:none; padding: 6px 12px; }
.navbar .nav>li>a.active, .navbar .nav>li>a:hover {background: var(--brand-color); color: #fff;}

.search .btn.btn-outline-secondary { border-color: #ced4da; border-radius: 0 4px 4px 0!important;}
.container .content section.search { padding:1.5em 5% 0;}
.container .content section.search h4 { background:#ccc; margin:0; padding:.5em .5em .5em 1.6em; font-size: 1.2em; font-weight:700; }
.container .content section.search h4 span { color:var(--brand-color); }

.container .content section.search .box_input{padding:1.5em .5em 1.5em 2em;}
.container .content section.search .box_input label{display:inline;padding-right:1em;font:bold 15px Helvetica,Arial,sans-serif;}
.container .content section.search .stats { font:bold 15px Helvetica,Arial,sans-serif;background:none repeat scroll 0 0 #EEE;border-top:2px solid var(--brand-color);margin-bottom:1.5em;padding:.5em 0 .5em 1.9em;}
.container .content section.search .box_selects{padding:0 0 0 2em;}
.container .content section.search .box_selects img{margin:.5em;}
.container .content section.search .box_selects .desde{padding-right:1.6em;}
.container .content section.search .box_selects .desde span,.container .content section.search .box_selects .hasta span,.container .content section.search .box_selects .ordenar span{font:bold 15px Helvetica,Arial,sans-serif;}
.container .content section.search .box_selects .ordenar{padding-top:1.5em;}
.container .content section.search .box_selects .ordenar input.btn, section.encuesta form .voto .btn, .container .comentarios .form-box .btn{ background-color:#900;background-image:linear-gradient(to bottom,#ED1A3B,#990000); filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ED1A3B',endColorstr='#990000',GradientType=0);}
.container .content section.search .noticias li li {background: url("/images/icons/bullet_seccion.png") no-repeat scroll -5px 9px rgba(0, 0, 0, 0);list-style-position:inside; font-size:14px;padding:5px 0;}
.container .content section.search .noticias article p{color:#333;font-size:14px;}
.container .content section.search .paginador{text-align:center;}
.container .content section.search .paginador a{background:none repeat scroll 0 0 transparent;border:1px solid #BBB;-webkit-border-radius:3px;-moz-border-radius:3px;border-radius:3px 3px 3px 3px;color:#BBB;display:inline-block;text-decoration:none;margin:2px!important;padding:5px 8px;}
.container .content section.search .paginador span{-webkit-border-radius:3px;-moz-border-radius:3px;border-radius:3px 3px 3px 3px;color:#000;display:inline-block;margin:2px!important;padding:5px 8px;}
.container .content section.search .paginador a:hover,.container .content section.search .paginador span.current{background:var(--brand-color);border:1px solid var(--brand-color);color:#FFF!important;}
.container .content section.search .paginador span.spaninput{border:1px solid #202020;-webkit-border-radius:3px;-moz-border-radius:3px;border-radius:3px 3px 3px 3px;color:#000;display:inline-block;margin:2px!important;padding:2px;}
.container .content section.search .paginador input{border:0 none;color:#000;font-family:Tahoma;font-size:12px;height:14px;text-align:center;width:32px;margin:2px;}
/*Tiempo meteorologico*/
ul.weather>li {font-size: 15px;}

article{margin-bottom:2em;border-bottom: 10px #f2f2f2 solid; }
article .box_seccion_hasttags{padding-top:0;}
article .hashtags .hashtag{display:inline-block;margin-right:.5em;}
article .hashtags .hashtag a{color:#fff;font-weight:400;font-size:13px;}
article aside ul.linenums li{background:url(/images/icons/bullet_seccion.png) no-repeat scroll -5px 4px;list-style-position:inside;list-style-type:none;}
article aside ul.linenums li a{background:none;padding-left:.5em;}
article.principal h2{font:bold 40px/38px "Miller Headline Bold", "Times New Roman", Times, Georgia, serif;letter-spacing:-2px;margin:5px 0 10px;}
article.normal h2{font:bold 34px/32px "Miller Headline Bold", "Times New Roman", Times, Georgia, serif;letter-spacing:-1px;margin:3px 0;}
article.principal h2 a:hover,article.normal h2 a:hover{text-decoration:none;}
article .seccion{font:bold 14px/16px Helvetica,Arial,sans-serif;color:var(--brand-color);text-transform:uppercase;padding-bottom:4px;display:block;}
article .seccion > a { color: var(--brand-color);}
article .seccion span.bullet{color:#000;position:relative;top:-1px;}
.post article h1{font:bold 42px/42px Georgia,"Times New Roman",times,serif;letter-spacing:-2px;}
.post article h2{font:bold 36px/36px Helvetica,Arial,sans-serif;color:#666;line-height:26px;}
section.breves{margin-bottom:2em;display:flex;}
section.breves.negro{background:#000;}
section.breves.blanco{background:#fff;}
section.breves article.breve{display:inline-block;width:33.3%;vertical-align:top;text-align:center;margin:0 2% 0 0; background-position:-105px bottom;padding-top:1em;box-sizing:border-box;}
section.breves.negro article{background-image:none;}
section.breves.negro article.gris{background:#282828;}
section.breves article .secciones{text-align:center;font:14px/16px Helvetica,Arial,sans-serif;color:var(--brand-color);}
section.breves article .secciones span{display:inline;}
section.breves.negro article .secciones span{color:#fff;}
section.breves.blanco article .secciones span{color:#282828;}
section.breves article .secciones span.seccion{padding-right:3px;}
section.breves article .secciones span.autor{padding-left:3px;}
section.breves article h1.titulo a{color:var(--brand-color);font-size:20px;line-height:normal;display:block;text-align:center;}
section.breves article .social{background:transparent;width:100%;}

.autores .author:not(:first-child) { padding-left: 2px; }
.autores .author:not(:first-child)::before { content: " | "; }
.autor_caretos .careto>img { max-width: 100px; }
.autor_caretos h1 { border-bottom:1px dotted #ccc;color:#666;}
.autor_caretos { border-bottom:2px solid var(--brand-color);}
.autor_caretos .autor_extras_post .twitter_careto{background:url(/images/icons/ico_redes_caretos.png) no-repeat scroll left top;padding-left:1.7em;}
.autor_caretos .autor_extras_post .rss_careto{background:url(/images/icons/ico_redes_caretos.png) no-repeat scroll left -80px;text-indent:-9999em;display:inline-block;padding-left:1.5em;}
.lista_autores .head_autores{border-bottom:2px solid #000;margin:2em 5%;}
.lista_autores li{float:left;list-style:none;width:28.5%;background:none repeat scroll 0 0 #EEE;border-radius:5px;height:50px;margin:.5em 1em;}
.lista_autores li img{display:block;height:40px;float:left;margin:5px 0 5px 5px;}
.lista_autores li a{font-size:16px;padding-left:10px;line-height:48px;}
section.error .img_error{text-align:center;position:relative;padding-bottom:2em;}
section.error .img_error img{max-width:100%;}
section.error .text_error{position:absolute;left:10%;top:3em;}
section.error h5{background:#fff;border-radius:10px;border:1px solid #eee;display:block;padding:.5em;}
.container .reaccions h3{font:bold 19px/32px Helvetica,Arial,sans-serif;background:#F4F4F4;border-bottom:1px dotted #ccc;color:#000;padding:3px 1em;}
.container .extras h4{font:bold 16px/18px Helvetica,Arial,sans-serif;text-transform:uppercase;color:#555;padding:.7em 0 0 2.7em;}
.container .extras .twitter{padding-bottom:0;}
.container .extras .twitter h4{background:url(/images/icons/ico_redes.png) no-repeat scroll left -635px;height:36px;}
.container .extras .twitter h4:hover{background-position:left -715px;}
.container .extras .comentarios h4{background:url(/images/icons/ico_redes.png) no-repeat scroll left -1085px;height:36px;}
.container .extras .opinio h4{background:url(/images/icons/ico_redes.png) no-repeat scroll left -933px;height:36px;}
.container .extras .opinio h5{font-size:20px;}
.container .extras .foto_lector h4{background:url(/images/icons/ico_redes.png) no-repeat scroll left -785px;}
.container .extras .foto_lector h4,.container .extras .foto_lector h4 a{font-size:15px;}
.container .extras .foto_lector h4 small{background:url(/images/icons/bullet_seccion.png) no-repeat scroll left 6px;display:block;font-size:13px;text-transform:lowercase;color:#000;padding:5px 0 0 10px;}
.container .extras .foto_lector .box_foto_lector{background:#eee;text-align:center;max-width:330px;}
.container .extras .foto_lector .box_foto_lector a img{padding:.4em;}
.box_extras_post {font-size:16px;}
article.principal .firma{color:#999;}
article.normal .firma, article.breve .firma{color:#999;line-height:2em;}
article .social .count a{color:#fff;font-size:14px;}
article .social .count a:hover{color:#000;text-decoration:none;}
article .social .comentarios:before{content:"Comenta: ";color:#999;}
article .social .redes:before{content:"Comparteix: ";color:#999;}
article .social .comentarios span,article .social .redes .count span{background: transparent url(/images/ico_redes.png) no-repeat left -556px;height:30px;display:inline-block;width:36px;text-align:center; }
article .social .redes .count span { text-indent: 100%; overflow: hidden;vertical-align:top;}
article .social .redes .twitter .count span { background-position:left -676px; }
article .social .redes .facebook .count span { background-position:left -735px; }
.container .content.post article .firma { font-style: italic; font-size:17px; color:#999; line-height:normal; padding-right:0; }
.firma>time:not(:first-child):before { content: " | "; }
.container .content.post section.comentarios{overflow:hidden;width:100%; display: flex; flex-direction: column;}
.container .content.post section.comentarios>.post_formulario{ order: 2;}
.container .content.post .box_extras_post .comentarios.btn{border:1px solid #eee;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;}
.container .content.post .cuerpo p{margin: 1rem 0; line-height:1.3em;}
.container .content.post .cuerpo .letra1 p{font-size:.7em;}
.container .content.post .cuerpo .letra2 p{font-size:.9em;}
.container .content.post .cuerpo .letra3 p{font-size:1em;}
.container .content.post .cuerpo .letra4 p{font-size:1.2em;}
.container .content.post .cuerpo .letra5 p{font-size:1.4em;}
.container .content.post .cuerpo p:has(img) { margin-left: auto; text-align: center; }
.container .content.post .cuerpo p>img { max-width:100%; height:auto;}
.container .content.post .cuerpo p.responsive { overflow:hidden; padding-bottom:56.25%!important; position:relative; height:0; } 
.container .content.post .cuerpo p.responsive iframe, .container .content.post .cuerpo p.responsive video { left:0; top:0; height:100%; width:100%; position:absolute; }
.container .content.post .cuerpo figure { text-align: center; }
.container .content.post .cuerpo figure>figcaption { text-align: left; background: #f5f5f5; padding:1em; margin-bottom: 1em;}
.container .content.post .cuerpo div.twitter-tweet { margin: 0 auto; }
.container .content.post .star-rating{margin:.2em .8em;}
.container .content.post .message{font-weight:700;margin-right:.5em;}
.container .content.post figure{position:relative;overflow:hidden;padding:0;text-align:center;}
.container .content.post .noticia figcaption{background:#000;opacity:.7;color:#fff;padding:.5em 1em;}
.container .content.post .noticia .box_social_post_up{border-bottom:2px solid #f5f5f5;}
table.rating_details_table td.sf_rating_bar_bg{background:#ADD8E6;}
table.rating_details_table div{background:blue;}
.star-rating{list-style:none;height:17px;position:relative;overflow:hidden;background:url(/images/alt_star.png) top left repeat-x;margin:0;padding:0;}
ul.star-rating{width:80px!important;}
.star-rating li{width:16px;height:17px;display:block!important;float:left;margin:0;padding:0;text-indent:100%}
.star-rating li a{display:block;width:16px;height:17px;line-height:17px;text-decoration:none;z-index:20;position:absolute;overflow:hidden;left:0;padding:0;}
.star-rating li a:hover{background:url(/images/alt_star.png) left -18px;z-index:2;left:0;border:none;}
.star-rating a.r1star{left:0;}
.star-rating a.r1star:hover{width:16px;}
.star-rating a.r2stars{left:16px;}
.star-rating a.r2stars:hover{width:32px;}
.star-rating a.r3stars{left:32px;}
.star-rating a.r3stars:hover{width:48px;}
.star-rating a.r4stars{left:48px;}
.star-rating a.r4stars:hover{width:64px;}
.star-rating a.r5stars{left:64px;}
.star-rating a.r5stars:hover{width:80px;}
.star-rating a.r6stars{left:80px;}
.star-rating a.r6stars:hover{width:96px;}
.star-rating a.r7stars{left:96px;}
.star-rating a.r7stars:hover{width:112px;}
.star-rating a.r8stars{left:112px;}
.star-rating a.r8stars:hover{width:128px;}
.star-rating a.r9stars{left:128px;}
.star-rating a.r9stars:hover{width:144px;}
.star-rating a.r10stars{left:144px;}
.star-rating a.r10stars:hover{width:160px;}
.star-rating li.current-rating{background:url(/images/alt_star.png) left -18px;position:absolute;height:17px;display:block;text-indent:-9000px;z-index:1;left:0;}
.container .comentarios h3 {background:#eee;font-size:16px;border-top:1px solid #ccc;border-bottom:1px dotted #000;}
.container .comentarios .accept_check{font-size:13px;}
.container .comentarios .sf_comment_simple { border-bottom:1px dotted #ccc; font-size: .8em;}
.container .comentarios .sf_comment_simple .sf_comment_text{ border-left:1px dotted #eee; }
.container .comentarios .alert-info p{font-size:12px;margin-bottom:0;}
.container .comentarios .form-box .btn { background-color:#900; border-color:#900; background-image:linear-gradient(to bottom,#ED1A3B,#990000);filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ED1A3B',endColorstr='#990000',GradientType=0);}
.container .comentarios .date{font:italic 14px/22px Georgia,"Times New Roman",times,serif;}
.comentarios .scrollable { overflow:hidden;background:transparent;width:680px;margin:0 10px 0 0; }
.comentarios .scrollable .items div.item{float:left;width:680px;padding-right:2em;}
.comentarios .scrollable .items div.item p{font:italic 16px/22px Georgia,"Times New Roman",times,serif;}
.scrollable .navi { display:inline-block;position:relative;top:5px; }
.scrollable .navi a{background:url(/images/icons/ico_mini_slider_home.png) no-repeat scroll left -167px transparent;display:block;float:left;height:12px!important;overflow:hidden;text-indent:100%;white-space:nowrap;width:12px!important;margin:3px;}
.scrollable .navi a:hover,.scrollable .navi a.active{background-position:0 -259px;}
.scrollable div.paginador{text-align:center;width:285px;margin:0 0 0 -20px;padding:.5em 0;}
.scrollable div.paginador a{display:inline-block;height:20px;text-indent:-9999em;width:32px;}
.scrollable div.paginador a.prev{background:url(/images/icons/ico_mini_slider_home.png) no-repeat scroll left 0 transparent;}
.scrollable div.paginador a.next{background:url(/images/icons/ico_mini_slider_home.png) no-repeat scroll left -43px transparent;}
.social_bt > span {display: inline-block;}
.social_bt .count {
background:transparent url(/images/ico_redes.png) no-repeat;
height:30px;
display:block;
float:left;
text-align:right;
width: 48px;
}
.social_bt .twitter .count { background-position:left -402px;}
.social_bt .facebook .count { background-position:left -321px;}
.social_bt .whatsapp .count {background-position:left -483px;}
.social_bt .count a{width:45px;display:block;font-size:14px;color:#fff;line-height:17px;padding:.05em; overflow:hidden;text-indent:100%;}

.print_box>a{background:transparent url(/images/ico_redes.png) no-repeat;height:30px;width:50px;display:inline-block;text-indent:100%;white-space:nowrap;overflow:hidden;margin-left:3px;}
.print_box>a.print {background-position:left -149px;margin-left:0;}
.print_box>a.mail {background-position:left -236px;}
.print_box>a.text_mas {background-position:left 1px;}
.print_box>a.text_menos {background-position:left -72px;}

#sf_comment_list div.pager{margin-bottom:.5em;}
#sf_comment_list div.pager div.indicador{border:none;float:left;font-size:.9em;margin:0 .5em;padding:.3em;}
#sf_comment_list div.pager div.botones{float:right;padding-right:1.5em;}
#sf_comment_list div.pager div.botones span.prevbutton{display:block;float:left;text-indent:100%;overflow:hidden;width:32px;height:21px;background:url(/images/prev.png);}
#sf_comment_list div.pager div.botones span.prevbutton.disabled{background:url(/images/prev.png) 0 21px;}
#sf_comment_list div.pager div.botones span.nextbutton{display:block;float:left;text-indent:100%;overflow:hidden;width:32px;height:21px;background:url(/images/next.png);}
#sf_comment_list div.pager div.botones span.nextbutton.disabled{background:url(/images/next.png) 0 21px;}
.container .content .fotogaleria .big_img{text-align:center;min-height:450px;}
.fotogaleria .head_galerias h3,.videogaleria .head_videogaleria h3,.encuesta .head_encuesta h3, .head_resultado h2, .head_historico h3{display:inline;font-size:15px;font-weight:400;line-height:normal;background:#000;color:#FFF;margin-left:1.4em;text-transform:uppercase;padding:.5em 1em .2em .5em;}
.fotogaleria .scrollable{position:absolute;width:83%;overflow:hidden;background:transparent;margin:0 28px;}
.fotogaleria .box_img_small a.next{background-position:5px -129px;margin-right:-101px;}
.fotogaleria .box_img_small a.prev{margin-left:-66px;}
.botones_fotogaleria{position:relative;top:-27.5em;width:100%;height:1px;}
.botones_fotogaleria a{cursor:pointer;}
.botones_fotogaleria .prev_link{position:relative;left:-16px;}
.botones_fotogaleria .next_link{position:relative;right:-16px;}
.botones_fotogaleria a.prev_foto{background:transparent url(/images/icons/ico_fotogalerias_slider.png) no-repeat scroll left top;text-indent:-9999em;width:4.5em;height:6em;}
.botones_fotogaleria a.next_foto{background:transparent url(/images/icons/ico_fotogalerias_slider.png) no-repeat scroll right top;text-indent:-9999em;width:4.5em;height:6em;}
.botones_fotogaleria a.prev_foto:hover,.botones_fotogaleria a.next_foto:hover{opacity:.5;}
.contador_imagenes{float:right;position:relative;top:-3.8em;left:3em;}
.contador_imagenes a{font-size:.8em;}
.videogaleria .scrollable{position:absolute;width:83%;overflow:hidden;background:transparent;margin:0 30px;}
.videogaleria .box_img_small a.next{background-position:5px -129px;margin-right:-1px;}
.videogaleria .box_img_small a.prev{margin-left:-1px;}
.container .content .videogaleria .big_img{text-align:center;background:#000;}
#loading{left:2em;position:absolute;top:3em;display:none;}
.box_social .estrellas{margin-top:9px;}
section.contacto .box_text span{background:none repeat scroll 0 0 #CFCFCF;display:block;font-weight:700;margin-bottom:1em;padding:.7em 1.5em;}
section.contacto .box_text p{padding-left:1.3em;}
.relacionado a{padding-left:1em;background:url(/images/icons/bullet_seccion.png) no-repeat scroll left 3px;}
section .head_encuesta h3{font:bold 16px/20px Helvetica,Arial,sans-serif;line-height:normal;background:url(/images/icons/ico_redes.png) no-repeat scroll left -1259px;height:36px;text-transform:uppercase;margin:0 0 0 1.4em;padding:.7em 0 0 2.7em;}
aside section.encuesta{overflow:visible;padding:1em 0;}
.container section.encuesta h4 { color:#000;margin-top:1em;background:transparent; font-size:17.5px; font-weight:700;}
.encuesta.ui-progressbar{-webkit-border-radius:3px;-moz-border-radius:3px;border-radius:3px;background:#fff;border:1px solid #ccc;height:1em;}
section.encuesta form{margin-top:10px;}
aside section.encuesta form{padding-bottom:10px;margin-left:-20px;padding-left:20px;}
section.encuesta form ul{padding-left:0em;}
section.encuesta form ul li label input{margin:-5px 5px 0 0;}
section.encuesta a.resultados{float:left;text-decoration:none;color:#000;background:#CFCFCF;width:100%;margin-bottom:1.5em;padding:.8em 0 .6em 1.6em;}
section.encuesta .voto a.resultados{float:left;text-decoration:none;color:#000;background:#CFCFCF;width:auto;margin-bottom:0;padding:.8em 0 .6em 1.6em;}
.lateral .encuesta h4 { color:#000;font-size:1em; margin-top:0; background:#CFCFCF;padding:.7em 1.8em;}
.lateral .encuesta .body_encuesta.row { display: block; } 
.lateral .encuesta form {margin-top: 15px; }
.lateral .encuesta form ul{padding-left:0;}
.encuesta.ui-progressbar .ui-progressbar-value{-webkit-border-radius:3px;-moz-border-radius:3px;border-radius:3px;height:100%;background:#e13728;color:#FFF;font-weight:700;border:1px solid #e3a1a1;}
.encuesta.ui-progressbar .count{float:right;font-size:.8em;color:silver;margin:-4px 4px 0 0;}
.encuesta.ui-progressbar.encuesta_2 .ui-progressbar-value{background:#ff8c04;border-color:#ff8c04;}
.encuesta.ui-progressbar.encuesta_3 .ui-progressbar-value{background:#dbd408;border-color:#dbd408;}
.encuesta.ui-progressbar.encuesta_4 .ui-progressbar-value{background:#94c93d;border-color:#94c93d;}
.encuesta.ui-progressbar.encuesta_5 .ui-progressbar-value{background:#2dc5f6;border-color:#2dc5f6;}
.encuesta.ui-progressbar.encuesta_6 .ui-progressbar-value{background:#3f66ab;border-color:#3f66ab;}
.encuesta.ui-progressbar.encuesta_7 .ui-progressbar-value{background:#6a246d;border-color:#6a246d;}
.encuesta.ui-progressbar.encuesta_8 .ui-progressbar-value{background:#c25b88;border-color:#c25b88;}
section.encuesta form .voto .btn{color:#fff;width:5em;background-color:#900;float:right;
background-image:linear-gradient(to bottom,#ED1A3B,#990000);filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ED1A3B',endColorstr='#990000',GradientType=0);margin:5px 13px 0 0;}
.encuestas h3 { font-size: 20px; line-height: 22px;}
.encuestas #polls_list { margin-right: 0;}
.encuestas #polls_list li.item { list-style-position: outside; padding-bottom: 2em; border-bottom: 1px solid #EEE; margin-bottom: 2em; list-style-type: none;}
.encuestas .sf_encuesta_results { padding-left: 3em;}
.post aside.lateral{padding-top:0em;}
.container>.content>.col-md-8{border-right:1px solid #CCC;}
.opinions .header_opinio span{font:bold 15px/20px Helvetica,Arial,sans-serif;background:url(/images/icons/ico_mas.png) no-repeat scroll 7px 8px #000;color:#FFF;margin-left:1.5em;text-transform:uppercase;padding:.5em 1em .2em 2em;}
.opinions .careto{float:right;width:8em;}
.feed { border-bottom: 2px solid #000; margin-left: -1.5em; margin-bottom: 1.5em;}
.feed header { border-bottom: 2px solid #000;}
.feed header img { max-height: 40px; margin-left: 1.5em; }
.feed article { margin: 1em 0 1em 1em; padding-bottom: 1em; }
.feed article a { font-size: 1.4em; color: #000}
.feed article:last-child { background: none; }
.lo_mas_visto ul li a { font:bold 15px/20px Helvetica,Arial,sans-serif; background:url(/images/icons/ico_mas.png) no-repeat scroll 7px 8px #888;color:#fff!important;text-transform:uppercase;margin-right:0;-webkit-border-radius:0;-moz-border-radius:0;border-radius:0;padding:.5em .9em .2em 1.9em; }
.lo_mas_visto ul li { border-right:1px solid #000; }
.lo_mas_visto ul li:last-child { border-right:0; }
.lo_mas_visto ul li.ui-tabs-active a,.lo_mas_visto ul li a:hover{background-color:#000;border:1px solid #000;}
.lo_mas_visto ol { color:var(--brand-color);font-size:25px;padding-left:.5em;border-top:2px solid #000;margin:0;list-style-position:inside;}
.lo_mas_visto ol li { border-bottom:1px dotted #000;padding:.3em 0;}
.lo_mas_visto ol li a { color:#000;font-size:18px;font-weight:700;}
.publicidad .publi_db { text-align:center;color:#ccc;font-size:10px;display:block;font-family:Helvetica,Arial,sans-serif; }
.box.galerias .fotogaleria { border-right:none;margin-top:5px;border:2px solid #000;padding:0; }
aside.lateral .box.galerias .fotogaleria .ico_zoom { background:url(/images/icons/ico_zoom.png) no-repeat center center;opacity:0;display:block; }
.box.galerias .galeria_texto h3 { margin-top:0;font-size:16px;padding-left:1em;}
aside.lateral .twitter { margin:0 0 2em -1.5em;padding:.5em 0 0 1.5em;}
aside.lateral .head_twitter { border-bottom:2px solid #2DAAE2;margin-left:-1.5em;}
aside.lateral .head_twitter h3 { display:inline;font:bold 15px/20px Helvetica,Arial,sans-serif;line-height:normal;background:url(/images/icons/ico_mas_w.png) no-repeat scroll 7px 8px #2DAAE2;color:#FFF;margin-left:1.4em;text-transform:uppercase;padding:.5em 1em .2em 2em;}
.extras .twitter li a{color:#A60000;}
.extras .twitter ul{max-height:200px;overflow-x:hidden;overflow-y:scroll;}
.extras .twitter iframe{margin-bottom:-5px!important;}
.extras .twitter .timeline-footer{background:rgba(0,0,0,0.1);padding:6px 10px;}
.extras .twitter .timeline-footer a{display:block;width:95%;text-align:left;background:#fff;border:1px #ccc solid;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;color:#707070;padding:6px;}
aside.lateral .fan .header_facebook h3{font:bold 15px/20px Helvetica,Arial,sans-serif;display:inline;line-height:normal;background:url(/images/icons/ico_mas_w.png) no-repeat scroll 7px 8px #3b5998;color:#FFF;text-transform:uppercase;padding:.5em 1em .2em 2em;margin-left:1.5em;}
section.envio{padding:2em 5% 1.5em;}
section.envio .head_envio{border-bottom:2px solid #000;margin-bottom:1em;}
section.envio .box_alert{color:#666;margin-bottom:20px;text-shadow:0 1px 0 rgba(255,255,255,0.5);background-color:#fff;border:1px solid #eee;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;padding:8px 35px 8px 14px;}
section.envio form input{width:40%;}
section.envio form textarea{width:60%;min-height:200px;}
section.envio .btn.btn-primary{width:6em;background-color:#900;background-image:linear-gradient(to bottom,#ED1A3B,#990000);filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ED1A3B',endColorstr='#990000',GradientType=0);margin:0 23px 1em 0;}
.fotolector .head_foto_lector {border-bottom:2px solid #000;margin:0 0 0 -1.5em;}
.fotolector .box_btn{background:#eee;padding:.3em .5em 0;}
.fotolector .box_btn .text{width:65%;font-family:helvetica;}
.fotolector .box_btn .btn{width:5em;background-color:#900;background-image:linear-gradient(to bottom,#ED1A3B,#990000);filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ED1A3B',endColorstr='#990000',GradientType=0);margin:5px 13px 0 0;}
.fotolector .box_btn .btn a{color:#fff;font:bold 13px/20px Helvetica,Arial,sans-serif;}
footer{border-bottom:3px solid #ED1A3B;}
footer:hover{border-bottom:3px solid #666;}
footer .top_footer{background:#eee;border-top:1px solid #ccc;height:25px;}
footer .head_title{border-bottom:2px solid #333;margin-bottom:1em;}
footer .head_title h4{background:#333;color:#D91B37;font:bold 15px/20px Helvetica,Arial,sans-serif;text-transform:uppercase;display:inline-block;margin:0;padding:.2em .5em;}
footer>div>nav{background:#000;}
footer nav ul.links{margin:15px 0;padding:0;text-align:center;}
footer nav ul.links li{display:inline-block;list-style:none;}
footer nav ul.links li a{font:13px/13px Helvetica,Arial,sans-serif;color:#fff;border-right:1px solid #fff;padding-right:1em;margin-right:1em;}
footer nav ul.links li.direccion a{border:none;padding-right:0;color:#E51A38;}
footer p{font:italic 14px/20px Georgia,"Times New Roman",times,serif;}
footer .pujar a{background:url(/images/icons/ico_pujar.png) no-repeat scroll center top;height:30px;display:block;text-indent:-9999em;white-space:nowrap;text-align:center;margin:0 auto;}
footer .pujar a:hover{background-position:center bottom;}

h1,a:hover,article h2 a,aside h5 a,aside h3 a:hover,.container .comentarios .accept_check label strong,.contador_imagenes span.resto{color:#000;}
a,aside h3 a,article h2 a:hover,aside section.lo_mas_visto li a:hover,aside h5 a:hover,article aside .linenums li a,.container .extras .opinio h5 a{color:var(--brand-color);}
.container .comentarios .sf_comment_author .date{display:block;}
.container .content section.search .head_search,section.contacto .head_contacto,.lateral .head_encuesta{border-bottom:2px solid #000;}
.container .content section.search .head_search h3,.lista_autores h2{background:url(/images/icons/ico_mas.png) no-repeat scroll 7px 8px #000;color:#FFF;display:inline;font:bold 15px Helvetica,Arial,sans-serif;margin-left:1.4em;text-transform:uppercase;padding:.5em 1em .2em 2em;}
.container .content section.search .stats span,section.error h3,.container .comentarios .sf_comment_userbox label span,.container .comentarios .sf_comment_commentbox label span,.container .comentarios .accept_check strong,.fotogaleria .head_galerias h3 span,.contador_imagenes span.actual,.videogaleria .head_videogaleria h3 span,section.contacto .head_contacto h3 span,.head_encuesta h3 span,.box.galerias .head_galerias h3 span,section.envio form span,.fotolector .head_foto_lector h3 span{color:var(--brand-color);}


.container .content.post article .message,.container .content.post article .comentarios,.fotogaleria .scrollable .items div.item,.videogaleria .scrollable .items div.item{float:left;}

.container .content section.search .noticias{padding:1.5em 0 0;}
.container .content section.search .noticias ol,article .box_firma_social{margin:0;}
article aside ul.linenums li p,.lo_mas_visto ul{margin-bottom:0;}
section.breves article .social .comentarios:before,section.breves article .social .redes:before{content:"";}
/*.extracto .foto,*/.post .noticia .cuerpo .foto,.extras .twitter .head_twitter/*,footer .box_ico_socials*/{display:none;}
.container .comentarios .sf_comment_commentbox,.lo_mas_visto{padding-bottom:2em;}
.container .comentarios .sf_comment_author_name,.extras .twitter li span.autor a{font-weight:700;}
.comentarios .scrollable .items,.fotogaleria .scrollable .items,.videogaleria .scrollable .items{clear:both;position:relative;width:20000em;}
.container .content .fotogaleria,.container .content .videogaleria{position:relative;}
.container .content .fotogaleria figcaption.pie,.container .content .videogaleria figcaption.pie{background-color:#F5F5F5;border:1px solid #E3E3E3;border-radius:4px 4px 4px 4px;box-shadow:0 1px 1px rgba(0,0,0,0.05) inset;min-height:20px;margin:20px 0;padding:0 10px;}
.fotogaleria .head_galerias,.videogaleria .head_videogaleria,.encuesta .head_encuesta, .head_resultado, .head_historico {border-bottom:2px solid #000;margin-bottom:1em;}
.container .content .encuestas,.estatico{border-right:1px solid #CCC;padding:2em 5%;}
.head_historico { margin: 0 15px 1em -1.5em;}
.head_list_encuestas,aside.lateral .fan .header_facebook{border-bottom:2px solid #3b5998;margin-bottom:.5em;}
.head_list_encuestas h2{background:url(/images/icons/ico_mas_w.png) no-repeat scroll 7px 8px #000;color:#FFF;display:inline;font:bold 15px/20px Helvetica,Arial,sans-serif;line-height:normal;margin-left:1.4em;text-transform:uppercase;padding:.5em 1em .2em 2em;}
.fotogaleria .box_img_small,.videogaleria .box_img_small,.box.galerias{padding-bottom:1em;}
.fotogaleria .box_img_small a,.videogaleria .box_img_small a{margin-right:6px;text-indent:-9999em;}
.fotogaleria .box_img_small a.browse,.videogaleria .box_img_small a.browse{background:url(/images/icons/ico_galeria_thumb.png) no-repeat;cursor:pointer;display:block;height:101px;width:30px;}
.fotogaleria .box_img_small a.next,.videogaleria .box_img_small a.next{background:url(/images/icons/ico_galeria_thumb.png) no-repeat 5px -129px;}
.fotogaleria .box_img_small a.next:hover,.fotogaleria .box_img_small a.next:active,.videogaleria .box_img_small a.next:hover,.videogaleria .box_img_small a.next:active{background-position:5px -395px;}
.fotogaleria .box_img_small a.prev:hover,.fotogaleria .box_img_small a.prev:active,.videogaleria .box_img_small a.prev:hover,.videogaleria .box_img_small a.prev:active{background-position:0 -261px;}
.fotogaleria .box_img_small a.active img,.videogaleria .box_img_small a.active img{border:.09em solid var(--brand-color);}
section.contacto,section.encuesta{overflow:hidden;padding:2em 5%;}
section.contacto .head_contacto h3,.lateral .head_encuesta h3,.box.galerias .head_galerias h3,section.envio .head_envio h3,.fotolector .head_foto_lector h3{background:url(/images/icons/ico_mas.png) no-repeat scroll 7px 8px #000;color:#FFF;display:inline;font:bold 15px/20px Helvetica,Arial,sans-serif;line-height:normal;margin-left:1.4em;text-transform:uppercase;padding:.5em 1em .2em 2em;}
.lo_mas_visto ol li:last-child{border-bottom:none;}
section.encuesta form ul li,.extras .twitter li{list-style:none;}
section.encuesta .voto,.box.galerias .galeria_texto{ background:#CFCFCF; padding: 3px;}
.opinions .header_opinio,.box.galerias .head_galerias{border-bottom:2px solid #000; margin: 1em 0 .5em;}
.opinions .opinio h3 a{display:block;font-size:20px;line-height:22px;}
.publicidad.robapaginas,.patrocinio,.fotolector{padding-bottom:1.5em;text-align:center;}
.box.galerias .fotogaleria .big_img,footer .pujar{text-align:center;}
aside.lateral .fan{margin-bottom:1.5em;}
footer nav ul.links li a:hover,footer nav ul.links li span,footer nav ul li a{color:#E51A38;}

@media (max-width: 767px) {
    .noticias aside .comentarios{ display:none;}
    .container .content .col-md-8 { border-right:0;}
}
@media screen and (max-width: 480px) and (orientation:portrait) {
    .navbar .nav>li>a { padding: 6px 8px;}
    .container .content{background:none;}
    header.header{background:none;margin-bottom:0;}
    .box_foto_lector{max-width:100%!important;}
    .container .extras .foto_lector .box_foto_lector a img{padding:2%;}
    .opinio{background:url(/images/layout/background_container.png) repeat-x scroll left top transparent;padding:.3em 1em;}
    section.envio{padding:2em 0 1.5em;}
    .container .content.post .cuerpo .letra3 p { font-size: 1.3em; line-height: 28px;}
    figure.img img{width:100%;padding-bottom:1em;}
    .container .comentarios .sf_comment_commentbox textarea{width:323px;}

    article .social .redes:before{margin-left:0;content:"Comparteix: ";}

    .container .content.post .noticia figcaption{padding:.2em .5em;}
    .comentarios div.pager div.botones{padding-right:0!important;}
    .container .comentarios .sf_comment_commentbox{padding-bottom:1em;}
    .container .content.post .noticia .video{height:auto!important;}
    .container .content.post .noticia .video object{height:214px;}
    .big_img.img_portada a{height:255px!important;}
    .videogaleria .scrollable,.fotogaleria .scrollable{width:82%;}
    .container .content .fotogaleria .big_img{height:auto;min-height:100%;}
    .botones_fotogaleria{position:relative;top:-16em;}
    section.breves{background:url(/images/layout/border_article.png) repeat-x scroll left bottom;margin-bottom:1.5em;padding-bottom:1.5em;}
    section.breves article.breve{width:93.5%;}
    .autor_caretos h1{margin-top:0;font-size:30px;}
    .autor_caretos .autor_extras_post{border-bottom:2px solid var(--brand-color);padding-bottom:.7em;margin-bottom:1em;}
    .autor_caretos .autor_extras_post .twitter_careto{background:url(/images/icons/ico_redes_caretos.png) no-repeat scroll left 3px;padding-left:1.7em;}
    .lista_autores{padding-bottom:25px;}
    .lista_autores .head_autores{border-bottom:2px solid #000;margin:1em 0;}
    .lista_autores h2{background:url(/images/icons/ico_mas.png) no-repeat scroll 7px 12px #000;color:#FFF;display:inline;font:bold 15px Helvetica,Arial,sans-serif;margin-left:0;text-transform:uppercase;padding:.5em 1em .2em 2em;}
    .lista_autores li{float:left;list-style:none;width:47%;background:none repeat scroll 0 0 #EEE;border-radius:5px;margin:.5em .3em;}
    .lista_autores li a{position:relative;top:-.2em;font-size:10px;padding-left:3px;line-height:30px;}
    footer nav ul.links li:nth-last-child(2) a { border-right: none;}
    /*footer .box_ico_socials{display:inherit!important;margin: 0 auto; width: 195px;}*/
    article aside,article .box_seccion_hasttags,.container .content .videogaleria,.container .content .fotogaleria,section.encuesta,section.contacto,.autor_caretos{padding:0;}
    header.header .navbar .date-search .date p,.videogaleria .head_videogaleria h3,.fotogaleria .head_galerias h3,section.contacto .head_contacto h3{font-size:12px;}
    header.header .navbar .date-search .search form,.lista_autores ul{margin:0;}
    .box_social_m,.lateral .box.fan,.lateral .head_twitter,.lateral .twitter.hash_tag,.lateral .lo_mas_visto,.lateral .box.galerias,.container .reaccions,footer .container .span4,.noticias aside .comentarios{display:none!important;}
    .lateral .encuesta .head_encuesta,.lateral .encuesta .head_encuesta h3,.lateral .opinions .header_opinio,.lateral .opinions .header_opinio span,.container .content.post h1,.container .content.post h2,.container .content.post aside.box_extras_post{margin-left:0;}
    .container h3.comentaris,.container h3.comenta,.container .comentarios .alert-info,.container .comentarios .sf_comment_simple .sf_comment_rating{margin-right:0;}
}
@media screen and (max-width: 320px) and (orientation:portrait) {
    header.header .navbar .nav li a{font-size:18px;padding:10px 8px;}
    .container .comentarios .sf_comment_commentbox textarea{width:360px;}
    footer nav ul.links{font-size:24px;margin:0 0 10px 33px;}
    .post aside.lateral span.publi_db{display:block;}
    .container .comentarios .form-box .btn{font-size:20px;height:2em;margin-right:13px;}
}
@media screen and (max-width: 768px) and (orientation:landscape) {
    header.container{height:150px;}
    header.header{background:none;}
    .box_social_m div .hashtags{width:840px;}
    .box_social_m{background:#eee;border-bottom:1px solid #CCC;border-top:4px solid var(--brand-color);}
    article.principal h2{font:bold 36px/30px "Miller Headline Bold","Times New Roman",Times,Georgia,serif;}
    article.normal h2{font:bold 24px/24px "Miller Headline Bold","Times New Roman",Times,Georgia,serif;}
    .lateral .head_encuesta h3,.box.galerias .head_galerias h3{background:url(/images/icons/ico_mas.png) no-repeat scroll 12px 11px #000;font:bold 13px Helvetica,Arial,sans-serif;margin-left:1.6em;}
    aside.lateral .head_twitter h3{background:url(/images/icons/ico_mas_w.png) no-repeat scroll 12px 11px #2DAAE2;font:bold 13px Helvetica,Arial,sans-serif;margin-left:1.6em;}
    aside.lateral .fan .header_facebook h3{background:url(/images/icons/ico_mas_w.png) no-repeat scroll 12px 11px #3B5998;font:bold 13px Helvetica,Arial,sans-serif;margin-left:1.6em;}
    .lo_mas_visto ul li a{background:url(/images/icons/ico_mas.png) no-repeat scroll 12px 11px #888;font:bold 13px Helvetica,Arial,sans-serif;padding:.5em .2em .2em 1.9em;}
    .breves .breve{width:27%!important;}
    .container .extras .foto_lector .box_foto_lector{width:322px;}
    .container .extras .foto_lector .box_foto_lector a img{width:45%;}
    .container .extras .foto_lector h4{background:url(/images/icons/ico_redes.png) no-repeat scroll left -776px transparent;}
    .comentarios .scrollable,.comentarios .scrollable .items div.item{width:550px;}
    footer nav ul.links{width:100%!important;}
    aside .lo_mas_visto ol,aside .lo_mas_visto ol li a{font-size:16px;}
}
@media only screen 
and (min-device-width : 800px) 
and (max-device-width : 1280px) 
and (orientation : portrait) {
  .container .extras .foto_lector .box_foto_lector{width:290px;padding:.5em .3em;}
  .container .extras .foto_lector .box_foto_lector img{width:135px;}
  .container .extras .foto_lector h4 small{display:block;}
  .container .content.post .noticia .video{height:auto!important;}
  .container .content.post .noticia .media img,.container .content.post .noticia .video img{width:800px;}
  .comentarios .scrollable{width:680px;}
  .comentarios .scrollable .items div.item{float:left;width:680px;padding-right:2em;}
  article.normal.item .social{width:17.4em;}
  article .social .redes:before{margin:0;padding:0;}
  article .social .redes .count span{width:25px;}
  .box.galerias .fotogaleria img{width:400px;}
  section.breves article.breve{width:27.5%;}
  .lo_mas_visto ul{width:300px;}
  .lo_mas_visto ul li a{padding:.5em .2em .2em 1.5em;}
  footer nav ul.links{width:100%;}
}
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {
  .box_social_m div .hashtags{width:750px;}
  .container .extras .foto_lector .box_foto_lector{width:290px;padding:.5em .3em;}
  .container .extras .foto_lector .box_foto_lector img{width:135px;}
  .container .extras .foto_lector h4 small{display:block;}
  .container .content.post .noticia .video{height:auto!important;}
  .comentarios .scrollable{width:575px;}
  .comentarios .scrollable .items div.item{float:left;width:575px;padding-right:2em;}
  article.normal.item .social{width:17.4em;}
  article .social .redes:before{margin:0;padding:0;}
  article .social .redes .count span{width:25px;}
  section.breves article.breve{width:27.5%;}
  .lo_mas_visto ul{width:300px;}
  .lo_mas_visto ul li a{padding:.5em .2em .2em 1.5em;}
  .box_social_m,footer nav ul.links{width:100%;}
}
.responsive { overflow:hidden; padding-bottom:56.25%!important; position:relative; height:0; } 
.responsive iframe, .responsive video { left:0; top:0; height:100%; width:100%; position:absolute; }

@media print {
 .lateral, nav, .search, .bullet, .comentarios.btn, .print_box, .social_bt, .comentarios, .extras, .star-rating, .box_ico_socials, footer {display: none;}
div, figure, img {padding:0; margin: 0;}
}

/*Searcher*/
.header div#buscador form { margin: 30px 15px; font-size:0.8em; }
.header div#buscador input#terms { background: white url(/images/iconos/search16.png) no-repeat scroll left center; padding-left: 20px; width: 120px; margin-bottom: 0; }
.header div#buscador input[type=submit] { border: outset 1px; font-weight: bold; }

/*Portadilla */
.portadilla { margin:10px 5px;border-bottom:10px #f2f2f2 solid;padding-bottom:5px;text-align:center;}
.portadilla h6 { color: #fff; background: #000; margin:0; font-size: 1.1em; padding:.25em;}
.portadilla img { max-width: 100%; box-sizing: border-box;}


.social-networks>a {
    background: url(/images/ico_redes.png) no-repeat 0 0;
    display: inline-block;
    height: 21px;
    width: 21px;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    margin-left: 6px;
}
.menu { background:#333; color: #fff; }
#menu>.nav-bar { background: #333; }
.menu:not(.menu-sm) .fecha::after { content: " | "; }
.menu.menu-sm {background:#f2f2f2; color:#000; font-size: .8em;}

.social-networks>a:hover{opacity:.7;}
.social-networks>a.facebook{background-position: -27px -612px;}
.social-networks>a.twitter{background-position: 0 -612px;}
.social-networks>a.instagram{background-position: -54px -612px;}
.social-networks>a.rss{background-position: 0 -636px;}
.post article h2.subtitulo{font:bold 24px/24px Helvetica, Arial; color:#958D7A; margin: 0px;}

/* Les mes */
.lesmes {margin: 10px 0 25px; border-top: 10px solid #f2f2f2;}
ul.tab { font-size:.9em;font-weight:bold; padding-bottom: 2px; }
ul.tab li { display:inline; border:1px solid #a60000; background: #a60000; padding: 4px; margin-left: 2px; border-radius: 5px 5px 0 0; border-bottom:none;}
ul.tab li a {color: white; text-decoration: none; }
ul.tab li.header { background: #d8dcdd; color: black; margin-left: 0px; padding: 4px 8px; }
ul.tab li.active { background: white; color: #a60000; border-color: #d8dcdd;}
ul.tab li.active a { color: #a60000; }
.lesmes .mesComentades{border:1px solid #d8dcdd; border-radius: 0 0 3px 3px;}

div.ultimas ol { margin: 0; padding-left: 30px; margin-bottom: 15px; }
div.ultimas ol li {padding: 0; margin: .3em 0; font-size: .9em;}
div.ultimas ol li a {color: #1b1c20;}

/*Footer*/
div#footer { font-size: 0.8em; color: #333333}
div#footer .container { padding: 0 1em;}
div#footer h5 {text-transform: uppercase; margin:0; margin: 0.8em 0 }
div#footer ul li a {text-decoration: none; color: #333333 }
div#footer ul li a:hover {text-decoration: underline }
div#footer div#contacto {float: right; margin-right: 15px; display: inline; }
div#footer div#contacto a { text-decoration: none; color: #333}
div#footer .legal {border-top: #333333 1px dotted; border-bottom: #333333 1px dotted; background: #f2f2f2;  padding: .5em 0; }
div#footer .legal ul{ font-weight: bold; }

.arxiu h2{color:#ad0101;font-size:1.6em;margin:0;}
.miniatura .footer {margin-top: .5em;}
.miniatura .pdfico{float: right; margin-right: 5px;}

.searcher { border: 1px solid black; padding: 10px; background: #e5e5e5; font-size: .85em;}
.searcher .border-bottom { border-color: silver!important;}


.arxiu h2.titulo { color: #453f41; font-size:1.6em; margin: 0;}

@media (min-width: 768px) {
.container .content section.search .stats h5 { float: left; }
.container .content section.search .paginador { float: right;padding:.1em 2em;}
header.header h1>a>svg { width:425px; height:75px; }
}
@media (min-width: 560px) {
    header.header .anys {font-size:1.3em; }
}
@media (max-width: 559px) and (min-width:481px) {
  header.header .anys {font-size:1.1em; }
}
@media (max-width: 480px) and (min-width:421px) {
  header.header .anys {font-size:1em; }
}
@media (max-width: 420px) {
  header.header .anys {font-size:0.85em; }
  .menu { font-size:15px; }
}
